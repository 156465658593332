// noinspection JSUnusedGlobalSymbols,JSUnresolvedVariable

import React from 'react'
import { Layout } from '../components/_layout'
import { Contacto } from '../components/Contacto'


export default function ContactoPage() {

  return (
      <Layout
          withNav
          seoTitle="Contacto"
          seoDescription="Empresa 100% mexicana"
      >
        <Contacto />
      </Layout>
  )
}
